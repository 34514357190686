<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              內容管理中心
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'CollectionList' }">
              資料集列表
            </b-breadcrumb-item>

            <b-breadcrumb-item active>
              {{ collection.name }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-8 mb-2 font-weight-bold">{{ collection.name }}</h4>
        <b-input-group class="col-12 col-xl-4 mb-2">
          <b-form-input v-model="keyword" placeholder="搜尋"></b-form-input>
          <b-input-group-append>
            <b-button @click="fetchItems"><i class="fa fa-search"></i ></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.COLLECTION_ITEM_MANAGE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'CollectionItemCreate' }"
            ><i class="fa fa-plus"></i>新增項目</b-button
          >
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="items"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.COLLECTION_ITEM_MANAGE])"
                    variant="inverse-warning"
                    :to="{ name: 'CollectionItemEdit', params: { id: collection.id, item_id: data.item.id }}"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.COLLECTION_ITEM_MANAGE])"
                    variant="inverse-danger"
                    @click="deleteItem(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchItems"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>

</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import { format } from "date-fns";
import collectionApi from "@/apis/collection";
import { mapState } from "vuex";

export default {
  data() {
    return {
      consts: consts,
      keyword: '',
      showLoading: false,
      fieldsInit: false,
      fields: [
        {
          key: 'created_at',
          label: '建立時間',
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
          },
        },
        { key: 'actions', label: '管理' },
      ],
      items: [],
      collection: {
        id: null,
        name: null,
        code: null,
        description: null,
        config: {
          fields: [],
        },
      },
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      formUrl: null,
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.collection.id = this.$route.params.id;
    this.fetchCollection();
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async fetchCollection () {
      try {
        this.showLoading = true;
        const response = await collectionApi.getCollection(this.collection.id);
        this.collection = response.data.data;

        if (!this.collection.config?.fields || this.collection.config.fields.length === 0) {
          this.$swal("提醒", "此資料集尚未設定欄位，須設定欄位後才能新增及編輯項目", "warning");
          this.$router.push({
            name: "CollectionList",
          });
          return;
        }

        let newField = this.collection.config.fields.map((field) => {
          return {
            key: field._id ?? field.config._id,
            label: field.config.title,
            sortable: true,
          };
        });

        this.fields.unshift(...newField);
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料集錯誤", "error");
      } finally {
        this.showLoading = false;
      }

      this.fetchItems();
    },
    async fetchItems () {
      try {
        this.showLoading = true;
        const response = await collectionApi.getItems(this.collection.id, {
          keyword: this.keyword,
          per_page: this.perPage,
          page: this.currentPage,
        });

        this.items = response.data.data.map((item) => {
          let newItem = {
            ...item
          };
          for (let i = 0; i < item.data.length; i ++) {
            const data = item.data[i];
            newItem[data.field_id] = data.value;
          }

          return newItem;
        });

        console.log(this.items);

        this.totalRows = response.data.data.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料集項目錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deleteItem(item) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "刪除資料集",
          text: `是否要將此項目刪除？`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "移除",
          reverseButtons: true,
        })

        if (! result.value) {
          return
        }

        await collectionApi.deleteItem(this.collection.id, item.id);
        this.$swal('刪除成功', '', 'success');
        await this.fetchItems();
      } catch (error) {
        console.error(error);
        this.$swal('刪除失敗', '', 'error');
      } finally {
        this.showLoading = false;
      }
    },
  }
}
</script>
